var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-3 mr-4",attrs:{"color":"indigo","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.title)+" ")])]}}]),model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('v-card',{staticClass:"pb-7",attrs:{"color":""}},[_c('v-snackbar',{attrs:{"absolute":"","color":_vm.uploadResult ? 'success' : 'error',"multi-line":_vm.multiLine},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){_vm.showUploadResult = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.showUploadResult),callback:function ($$v) {_vm.showUploadResult=$$v},expression:"showUploadResult"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.uploadResult ? '更新成功' : _vm.errorText)}})]),_c('v-toolbar',{staticClass:"elevation-0 pa-0",attrs:{"color":"transparent"}},[_c('v-card-title',{staticClass:"px-0 accent--text font-weight-bold"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showPopup = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"ma-0 px-5"},[_c('v-col',{staticClass:"px-5",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",attrs:{"height":"300","elevation":hover ? 10 : 3,"color":"blue lighten-5"},on:{"click":_vm.download}},[_c('v-card',{staticClass:"text-center",attrs:{"height":"100%","flat":"","color":"blue darken-2"}},[_c('div',{staticClass:"card-avatar"},[_c('v-avatar',{attrs:{"color":"blue lighten-5","size":"80"}},[(_vm.loadingDownload)?_c('v-progress-circular',{attrs:{"color":"blue darken-2","indeterminate":""}}):_c('v-icon',{attrs:{"color":"blue darken-2","large":""}},[_vm._v(" mdi-download ")])],1)],1),_c('div',{staticClass:"mt-5 card-word"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"blue lighten-5","text-color":"blue darken-2"}},[_vm._v("下載 BSID")])],1)])],1)]}}])})],1),_c('v-col',{staticClass:"px-5",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",attrs:{"height":"300","elevation":hover ? 10 : 3,"color":"cyan lighten-5"},on:{"click":_vm.triggerSelectFile}},[_c('v-card',{staticClass:"text-center",attrs:{"height":"100%","flat":"","color":"cyan darken-3"}},[_c('div',{staticClass:"card-avatar"},[_c('v-avatar',{attrs:{"color":"cyan lighten-5","size":"80"}},[(_vm.loadingUpload)?_c('v-progress-circular',{attrs:{"color":"cyan darken-3","indeterminate":""}}):_c('v-icon',{attrs:{"color":"cyan darken-3","large":""}},[_vm._v(" mdi-upload ")])],1)],1),_c('div',{staticClass:"mt-5 card-word"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"cyan lighten-5","text-color":"cyan darken-3"}},[_vm._v("更新 BSID")])],1)])],1)]}}])})],1)],1),_c('v-file-input',{ref:"files",staticClass:"d-none",attrs:{"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":_vm.upload},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }