<template>
  <v-card class="SchoolCardReaderRateCard pa-3 mb-4">
    <v-toolbar color="transparent" class="elevation-0 pa-0">
      <v-card-title class="accent--text font-weight-bold">
        最佳契約容量試算
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('input', false)" plain>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-stepper v-model="bindStep">
        <v-stepper-items>
          <v-stepper-content class="pa-4" :step="1">
            <v-form ref="form" @submit.prevent="calculateBest">
              <v-sheet class="white pa-4 mb-4" rounded="lg">
                <div>
                  <div class="d-flex align-center mb-3">
                    <v-avatar color="accent" size="25">
                      <span class="white--text">1</span>
                    </v-avatar>
                    <div class="ml-2 accent--text font-weight-bold">
                      請輸入尖峰契約容量
                    </div>
                  </div>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="peakContractOpacity"
                        type="number"
                        @keydown="validateNumericInput"
                        outlined
                        dense
                        background-color="white"
                        :rules="[(v) => !!v || '必填']"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="mt-6">
                  <div class="d-flex align-center mb-3">
                    <v-avatar color="accent" size="25">
                      <span class="white--text">2</span>
                    </v-avatar>
                    <div class="ml-2 accent--text font-weight-bold">
                      請輸入每月最高尖峰用電 kW 數
                    </div>
                  </div>
                  <v-row class="mb-2">
                    <template v-for="(month, index) in monthlyHighest">
                      <v-col cols="6" sm="2">
                        <div
                          style="display: grid; grid-template-columns: auto 1fr"
                          class="align-center"
                        >
                          <div class="text-center pr-2">{{ index + 1 }}月</div>
                          <v-text-field
                            v-model="monthlyHighest[index]"
                            class="mb-2"
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </template>
                  </v-row>
                </div>
                <div>
                  <div class="d-flex align-center mb-3">
                    <v-avatar color="accent" size="25">
                      <span class="white--text">3</span>
                    </v-avatar>
                    <div class="ml-2 accent--text font-weight-bold">
                      請輸入台電(低壓)契約用電價格
                    </div>
                  </div>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-sheet color="white" class="pa-2 grid">
                        <div>每瓩/每月</div>
                        <div>夏月</div>
                        <div>非夏月</div>
                        <div class="d-flex align-center">尖峰/每瓩</div>
                        <div>
                          <v-text-field
                            v-model="taipowerPrice[0]"
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div>
                          <v-text-field
                            v-model="taipowerPrice[1]"
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div class="d-flex align-center">半尖峰/每瓩</div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div class="d-flex align-center">離峰/每瓩</div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-sheet color="white" class="pa-2 grid">
                        <div>每度電價</div>
                        <div>夏月</div>
                        <div>非夏月</div>
                        <div class="d-flex align-center">尖峰/每瓩</div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div class="d-flex align-center">半尖峰/每瓩</div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div class="d-flex align-center">離峰/每瓩</div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                        <div>
                          <v-text-field
                            dense
                            hide-details
                            outlined
                            background-color="white"
                            :rules="[(v) => !!v || '必填']"
                            type="number"
                            @keydown="validateNumericInput"
                          ></v-text-field>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </div>
              </v-sheet>
              <div class="mb-4 ml-2">
                <v-btn color="primary" @click="resetForm" outlined class="mr-2">
                  清除
                </v-btn>
                <v-btn color="primary" type="submit"> 試算結果 </v-btn>
              </div>
            </v-form>
          </v-stepper-content>
          <v-stepper-content :step="2">
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="text-h6 font-weight-bold text-center white--text"
              >
                <v-sheet color="primary" dark rounded="lg" class="pa-5"
                  >最佳尖峰契約容量<br />{{
                    best.settingsPeakContractOpacity
                  }}
                  kW</v-sheet
                >
                <v-sheet color="success" dark rounded="lg" class="pa-5 mt-4"
                  >節省金額預估<br />$ {{ Math.abs(best.saving) }}</v-sheet
                >
                <v-row>
                  <v-col>
                    <v-btn
                      class="mt-4"
                      block
                      color="primary"
                      outlined
                      large
                      @click="bindStep = 1"
                      >返回設定</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      class="mt-4"
                      block
                      color="primary"
                      outlined
                      large
                      @click="convertResultToCSV"
                      >匯出試算結果</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="8">
                <v-sheet
                  class="accent--text text-h6 mb-2 py-2 px-4"
                  rounded="lg"
                >
                  尖峰契約容量：{{ peakContractOpacity }} kW
                </v-sheet>

                <v-data-table
                  :headers="headers"
                  :items="result"
                  hide-default-footer
                  disable-sort
                  :items-per-page="-1"
                >
                </v-data-table>

                <v-sheet rounded="lg">
                  <v-row no-gutters class="mt-2 py-2 px-4 accent--text">
                    <v-col>
                      <v-sheet>
                        <div>全年基本電費</div>
                        <div class="text-right">{{ resultTotalBasic }}</div>
                      </v-sheet>
                    </v-col>
                    <v-divider class="mx-4" vertical></v-divider>
                    <v-col>
                      <v-sheet>
                        <div>全年超約罰款</div>
                        <div class="text-right">{{ resultTotalFine }}</div>
                      </v-sheet>
                    </v-col>
                    <v-divider class="mx-4" vertical></v-divider>
                    <v-col>
                      <v-sheet>
                        <div>全年尖峰總電價</div>
                        <div class="text-right">
                          {{ resultTotalBasic + resultTotalFine }}
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      bindStep: 1,
      peakContractOpacity: null,
      monthlyHighest: new Array(12).fill(null),
      taipowerPrice: new Array(12).fill(null),
      // peakContractOpacity: 1150,
      // monthlyHighest: [
      //   1084, 960, 1032, 1072, 1148, 1208, 1164, 1224, 1280, 1152, 1188, 1052
      // ],
      // taipowerPrice: [236.2, 173.2],
      headers: [
        {
          text: '月份',
          value: 'month'
        },
        {
          text: '最高尖峰用電 kW 數',
          value: 'highest'
        },
        {
          text: '超約 kW 數',
          value: 'over'
        },
        {
          text: '每月基本電費',
          value: 'basic'
        },
        {
          text: '超約罰款',
          value: 'fine'
        }
      ],
      headers2: [
        {
          text: '項目',
          value: 'serialNumber'
        },
        {
          text: '台電(低壓)契約用電價格表',
          value: 'serialNumber'
        }
      ],
      items: [
        { month: 1, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 2, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 3, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 4, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 5, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 6, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 7, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 8, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 9, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 10, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 11, highest: 1233, over: 50, basic: 1600, fine: 200 },
        { month: 12, highest: 1233, over: 50, basic: 1600, fine: 200 }
      ],
      best: {
        settingsPeakContractOpacity: this.peakContractOpacity,
        saving: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      navs: 'page/navs'
    }),
    selectedIds() {
      return this.selected.map((item) => item.emsclasscardreaderid)
    },
    cardList() {
      return this.cardreaders.map((cardId) => {
        return {
          cardId: cardId
        }
      })
    },
    result() {
      return this.monthlyHighest.map((value, index) => ({
        month: index + 1,
        highest: value,
        over:
          value - this.peakContractOpacity > 0
            ? Math.round((value - this.peakContractOpacity) * 100) / 100
            : 0,
        basic: this.calculateBasic(
          index >= 5 && index <= 8,
          this.peakContractOpacity
        ),
        fine: this.calculateFine(
          value,
          index >= 5 && index <= 8,
          this.peakContractOpacity
        )
      }))
    },
    resultTotalBasic() {
      return Number(
        this.result.reduce((acc, curr) => acc + curr.basic, 0).toFixed(1)
      )
    },
    resultTotalFine() {
      return Number(
        this.result.reduce((acc, curr) => acc + curr.fine, 0).toFixed(1)
      )
    }
  },
  mounted: function () {
    // this.init()
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    validateNumericInput(evt) {
      return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
    },
    resetForm() {
      this.$refs.form.reset()
      this.peakContractOpacity = null
      this.monthlyHighest = new Array(12).fill(null)
      this.taipowerPrice = new Array(12).fill(null)
    },
    calculateBasic(isSummer, peakContractOpacity) {
      return Number(
        peakContractOpacity &&
          (peakContractOpacity * this.taipowerPrice[isSummer ? 0 : 1]).toFixed(
            1
          )
      )
    },
    calculateFine(usage, isSummer, peakContractOpacity) {
      let overValue = Math.round((usage - peakContractOpacity) * 100) / 100
      if (overValue > 0) {
        let fineScale =
          overValue * 2 +
          (overValue < peakContractOpacity * 0.1
            ? 0
            : (Math.round((overValue - peakContractOpacity) * 100) / 100) * 0.1)
        return Number(
          (this.taipowerPrice[isSummer ? 0 : 1] * fineScale).toFixed(1)
        )
      }
      return 0
    },
    calculateBest() {
      if (this.$refs.form.validate()) {
        let currentTotalFine = this.resultTotalBasic + this.resultTotalFine
        this.best = {
          settingsPeakContractOpacity: this.peakContractOpacity,
          saving: 0
        }
        if (this.peakContractOpacity) {
          for (
            let x = Number(this.peakContractOpacity) - 1000;
            x <= Number(this.peakContractOpacity) + 1000;
            x += 1
          ) {
            if (x > 0) {
              let totalFine = Number(
                this.monthlyHighest
                  .map(
                    (value, index) =>
                      this.calculateBasic(index >= 5 && index <= 8, x) +
                      this.calculateFine(value, index >= 5 && index <= 8, x)
                  )
                  .reduce((acc, curr) => curr + acc, 0)
                  .toFixed(1)
              )
              if (totalFine - currentTotalFine < this.best.saving) {
                this.best = {
                  settingsPeakContractOpacity: x,
                  saving: Number((totalFine - currentTotalFine).toFixed(1))
                }
              }
            }
          }
        }
        this.bindStep = 2
      }
    },
    convertResultToCSV() {
      const csvRows = []
      const headers = this.headers.map(({ text }) => text)
      csvRows.push(headers.join(','))
      for (let index = 0; index < this.result.length; index++) {
        const values = Object.values(this.result[index])
        csvRows.push(values.join(','))
      }
      csvRows.push(`全年基本電費,${this.resultTotalBasic}`)
      csvRows.push(`全年超約罰款,${this.resultTotalFine}`)
      csvRows.push(
        `全年尖峰總電價,${this.resultTotalBasic + this.resultTotalFine}`
      )
      this.downloadCSV(csvRows.join('\n'), '契約容量試算結果.csv')
    },
    downloadCSV(csvString, filename) {
      const blob = new Blob([(csvString ? '\ufeff' : '') + csvString], {
        type: 'text/csv;charset=utf-8;'
      })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
      URL.revokeObjectURL(url)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/schoolInfoDialog.scss';
.grid {
  display: grid;
  grid-template-columns: auto repeat(2, auto);
  > div > div {
    padding: 4px;
    &:not(:first-child) {
      height: 48px;
    }
  }
}

::v-deep {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
