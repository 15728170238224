<template>
  <v-dialog v-model="showEdit" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="ems-accent-secondary"
        dark
        v-bind="attrs"
        v-on="on"
        class="my-3 mr-4"
      >
        建築名稱設定
      </v-btn>
    </template>
    <v-card tile>
      <v-toolbar color="transparent" class="elevation-0 pa-0">
        <v-card-title class="px-0 accent--text font-weight-bold"
          >建築名稱設定</v-card-title
        >
        <v-spacer></v-spacer>
        <v-btn @click="showEdit = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5">
        <div class="mb-4 ml-2">建築名稱</div>
        <template v-for="building in buildings">
          <BuildingNameEditor
            :originBuilding="building"
            @updateBuildName="(e) => (building.name = e)"
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BuildingNameEditor from './BuildingsNameSettings/BuildingNameEditor.vue'
import Building from '@/api/ems/Building'

export default {
  components: { BuildingNameEditor },
  data() {
    return {
      showEdit: false,
      buildings: []
    }
  },
  watch: {
    showEdit(val) {
      if (val) this.getBuildings()
    }
  },
  methods: {
    async getBuildings() {
      try {
        const { data } = await Building.getAll(this.$route.params.tagId)
        this.buildings = data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/schoolInfoDialog.scss';
</style>
