<template>
  <v-card class="SchoolCardReaderRateCard pa-3 mb-4">
    <v-toolbar color="transparent" class="elevation-0 pa-0">
      <v-card-title class="px-0 accent--text font-weight-bold">
        儲值機黑名單設定
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn @click="close()" plain v-if="showCloseBtn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-data-table
          :headers="headers"
          :items="cardList"
          :items-per-page="cardList.length"
          hide-default-footer
          disable-sort
          item-key="emsclasscardreaderid"
        >
          <template v-slot:top>
            <div class="d-flex">
              <v-sheet color="white" elevation="1" class="pa-4" width="300">
                <div class="subtitle-1 accent--text font-weight-bold">
                  新增黑名單
                </div>
                <v-row align="center" class="mt-0">
                  <v-col>
                    <v-text-field
                      v-model="cardreaderId"
                      filled
                      solo
                      placeholder="請輸入卡號"
                      width="300"
                      :rules="[(v) => !!v || '必填', daeCardRule]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="save()" :disabled="!valid" color="primary">
                      新增
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-alert
                class="ml-4 mb-0 pa-4 pl-5 font-weight-bold"
                color="error"
                dark
                text
                border="left"
              >
                <div class="mb-3">使用儲值機黑名單設定功能，請確認</div>
                <ol>
                  <li class="mb-2">儲值機要維持開機狀態</li>
                  <li>儲值機與IPC的連線正常</li>
                </ol>
              </v-alert>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="deleteCard(item)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardReaderBlack from '@/api/ems/CardReaderBlack'
import settings from '@/settings'

export default {
  name: 'CardreaderBlockCard',
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      valid: true,
      cardreaderId: null,
      cardreaders: [],
      headers: [
        {
          text: '卡號',
          value: 'cardId',
          cellClass: ['singleLine']
        },
        {
          text: '',
          value: 'action',
          align: 'right',
          cellClass: ['singleLine']
        }
      ],
      daeCardRule: (cardCode) => {
        let daeRule = new RegExp(/^[A-Z][0-9]{5}$/)
        let oringRule = new RegExp(/^[0-9]{3}-[0-9]{3}$/)
        let isOring = ['keelung', 'changhua'].includes(settings.edu.city)
        let validCardRuleResult = isOring
          ? oringRule.test(cardCode)
          : daeRule.test(cardCode)
        return (
          validCardRuleResult ||
          `卡號輸入錯誤，格式如 ${isOring ? '000-000' : 'A12345'}`
        )
      }
    }
  },
  computed: {
    ...mapGetters({
      navs: 'page/navs'
    }),
    selectedIds() {
      return this.selected.map((item) => item.emsclasscardreaderid)
    },
    cardList() {
      return this.cardreaders.map((cardId) => {
        return {
          cardId: cardId
        }
      })
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getList()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    close() {
      this.$emit('close', true)
    },
    getList() {
      CardReaderBlack.list(this.schoolId)
        .then((resp) => {
          this.cardreaders = resp.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    save() {
      this.setOverlayLoading(true)
      setTimeout(() => {
        this.setOverlayLoading(false)
        this.getList()
      }, 2000)
      CardReaderBlack.create(this.schoolId, this.cardreaderId)
        .then(() => {
          this.showMsg({
            message: '新增成功',
            time: 2000
          })
          this.getList()
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.message
              ? error.response.data.message
              : '新增失敗'
          this.showMsg({
            message: message,
            color: 'error',
            time: 2000
          })
        })
        .finally(() => {
          this.setOverlayLoading(false)
        })
    },
    deleteCard(card) {
      this.setOverlayLoading(true)
      CardReaderBlack.delete(this.schoolId, card.cardId)
        .then(() => {
          this.showMsg({
            message: '刪除成功',
            time: 2000
          })
          this.getList()
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.message
              ? error.response.data.message
              : '刪除失敗'
          this.showMsg({
            message: message,
            color: 'error',
            time: 2000
          })
        })
        .finally(() => {
          this.setOverlayLoading(false)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/schoolInfoDialog.scss';
</style>
