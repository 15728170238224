<template>
  <v-dialog v-if="!isOring" fullscreen hide-overlay v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" class="my-3 mr-4">
        儲值機黑名單設定
      </v-btn>
    </template>
    <CardreaderBlockCard
      :schoolId="schoolId"
      showCloseBtn
      @close="dialog = false"
    />
  </v-dialog>
</template>

<script>
import CardreaderBlockCard from './Card'
import settings from '@/settings'

export default {
  name: 'CardreaderBlockDialog',
  mixins: [],
  components: {
    CardreaderBlockCard
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    schoolId: {
      type: String,
      require: true
    }
  },
  data: function () {
    return {
      dialog: false,
      meterData: null
    }
  },
  computed: {
    isOring() {
      return ['keelung', 'changhua'].includes(settings.edu.city)
    }
  },
  watch: {
    value(val) {
      this.dialog = val
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    save() {
      this.$emit('save', this.meterData)
      this.dialog = false
    },
    close() {
      this.$emit('close')
      this.dialog = false
    }
  }
}
</script>

<style></style>
