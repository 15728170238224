<template>
  <v-row>
    <v-col cols="6" md="4" class="pb-0">
      <v-form v-model="valid">
        <v-text-field
          outlined
          dense
          background-color="white"
          v-model="buildingName"
          :disabled="!editMode || loading"
          flat
          :rules="editMode ? [buildingNameRule] : []"
        >
        </v-text-field>
      </v-form>
    </v-col>
    <v-col cols="6" class="pb-0">
      <div class="d-flex align-center">
        <v-btn v-if="loading" depressed loading disabled></v-btn>
        <v-btn
          v-else-if="!editMode"
          depressed
          color="primary"
          @click="
            editMode = !editMode
            hasExecuted = false
          "
          >編輯</v-btn
        >
        <template v-else-if="editMode">
          <v-btn
            depressed
            color="accent"
            @click="updateBuildingName"
            :disabled="!valid"
            >確認</v-btn
          >
          <v-btn
            depressed
            color="ems-blue-grey-2"
            class="ml-2"
            outlined
            @click="
              editMode = false
              hasExecuted = false
              buildingName = originBuilding.name
            "
            >取消</v-btn
          >
        </template>
        <div
          v-if="hasExecuted"
          :class="result ? 'success--text' : 'error--text'"
          class="ml-4"
        >
          {{ result ? '更新成功！' : '更新失敗！' + errorMessage }}
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Building from '@/api/ems/Building'

export default {
  props: {
    originBuilding: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      buildingName: '',
      editMode: false,
      loading: false,
      result: false,
      hasExecuted: false,
      valid: false,
      buildingNameRule(value) {
        const rule = new RegExp(/^[A-Z-\u4e00-\u9fa5]+$/)
        return (value && rule.test(value)) || '名稱可輸入中文字、英文大寫或 "-"'
      },
      errorMessage: ''
    }
  },
  watch: {
    hasExecuted(val) {
      if (val && this.result)
        setTimeout(() => {
          this.hasExecuted = false
        }, 3000)
    }
  },
  mounted() {
    this.buildingName = this.originBuilding.name
  },
  methods: {
    async updateBuildingName() {
      this.hasExecuted = false
      this.loading = true
      try {
        await Building.updateBuildingName(
          this.originBuilding.id,
          this.buildingName
        )
        this.$emit('updateBuildName', this.buildingName)
        this.result = true
      } catch (error) {
        console.error(error.response.data.message)
        this.errorMessage = error.response.data.message
        this.result = false
      } finally {
        this.loading = false
        this.hasExecuted = true
        this.editMode = !this.result
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/schoolInfoDialog.scss';

::v-deep.v-input--is-disabled {
  fieldset {
    border-color: transparent;
  }
  .v-input__slot {
    background: var(--v-ems-light-grey-base) !important;
  }
  input {
    color: var(--v-ems-bluegrey-base) !important;
  }
}
</style>
