<template>
  <div v-if="schoolData">
    <div class="mt-2 pr-3 text-right">
      <v-btn
        @click="showContractOpacity = true"
        color="success"
        dark
        class="my-3 mr-4"
        v-permission="['SCH_ADMIN', 'SCH_AFFAIRS']"
      >
        最佳契約容量試算
      </v-btn>
      <v-dialog v-model="showContractOpacity" fullscreen hide-overlay>
        <ContractFormContent
          v-if="showContractOpacity"
          :school-id="tagId"
          @input="showContractOpacity = false"
        />
      </v-dialog>
      <BsidSettings
        :school-id="tagId"
        v-permission="['SCH_ADMIN', 'SCH_AFFAIRS']"
      />
      <CardreaderBlockDialog
        :school-id="tagId"
        v-permission="['SCH_ADMIN', 'SCH_AFFAIRS']"
      />
      <CardReaderRateDialog
        :school-id="tagId"
        v-permission="['SCH_ADMIN', 'SCH_AFFAIRS']"
      />
      <BuildingsNameSettings v-permission="['SCH_ADMIN', 'SCH_AFFAIRS']" />
      <editClassroomNameCard
        v-permission="['SCH_ADMIN', 'SCH_AFFAIRS']"
        :schoolId="tagId"
      />
      <v-btn
        elevation="0"
        @click="checkSubmit"
        color="accent"
        :disabled="!valid"
        v-permission="['SCH_ADMIN']"
      >
        儲存修改
      </v-btn>
    </div>
    <div class="text-right pr-3 error--text text-subtitle-2" v-if="!valid">
      *尚有資料未填寫或格式錯誤
    </div>
    <div class="pa-3">
      <v-progress-linear v-if="loadingPage" indeterminate></v-progress-linear>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card
          class="mx-auto pa-3 ems-opacity-50-bg frosted-glass"
          elevation="0"
          v-if="infoData"
        >
          <v-container fluid>
            <v-row>
              <v-col>
                <h3 class="accent--text">基本資料</h3>
              </v-col>
            </v-row>
            <v-row>
              <template v-for="item in infoData">
                <v-col cols="12" lg="6" class="pt-0" :key="item.label">
                  <div class="text-subtitle-1 grey--text">{{ item.label }}</div>
                  <template v-if="item.type === 'select'">
                    <v-select
                      :readonly="!editAuthority || item.disabled"
                      outlined
                      v-model="item.value"
                      dense
                      v-bind="item.attrs"
                    ></v-select>
                  </template>
                  <template v-else-if="item.type === 'textarea'">
                    <v-textarea
                      :readonly="!editAuthority || item.disabled"
                      v-model="item.value"
                      outlined
                      dense
                      :no-resize="item.disabled"
                      v-bind="item.attrs"
                    >
                    </v-textarea>
                  </template>
                  <template v-else-if="item.key === 'roofPvCapacity'">
                    <v-text-field
                      :value="roofPvCapacity"
                      outlined
                      dense
                      :type="item.type || 'text'"
                      :readonly="!editAuthority || item.disabled"
                      :rules="item.rules"
                    ></v-text-field>
                  </template>
                  <template v-else-if="item.key === 'fieldPvCapacity'">
                    <v-text-field
                      :value="item.value"
                      @input="updateRoofCapcity"
                      outlined
                      dense
                      :type="item.type || 'text'"
                      :readonly="!editAuthority || item.disabled"
                      :rules="item.rules"
                      :error="!fieldPvCapacityValid"
                      :error-messages="
                        fieldPvCapacityValid
                          ? ''
                          : '設定值需小於全校太陽能裝置容量'
                      "
                    ></v-text-field>
                  </template>
                  <v-text-field
                    v-else
                    v-model="item.value"
                    outlined
                    dense
                    :type="item.type || 'text'"
                    :readonly="!editAuthority || item.disabled"
                    :rules="item.rules"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card>
        <SchoolMeter
          v-if="schoolData && schoolData.meterList"
          v-model="schoolData.meterList"
          :school-id="tagId"
          @updateSolarMeterCapcity="updateSolarMeterCapcity"
        />
      </v-form>
    </div>
    <v-dialog v-model="dialog" persistent width="350">
      <v-card v-if="!update.msg && !update.loading">
        <v-card-text class="pt-5 headline text-center">
          確定要修改嗎？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" @click="dialog = false"> 取消 </v-btn>
          <v-btn color="primary" elevation="0" @click="submit"> 更新 </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="update.loading">
        <div class="text-center pa-5">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-text class="pt-5 headline text-center">
          {{ update.msg }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" @click="dialog = false"> 關閉 </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BranchSchool from '@/api/ems/BranchSchool'
import SchoolMeter from './schoolMeter'
import editClassroomNameCard from './editClassroomNameCard'
import CardReaderRateDialog from './CardReaderRateDialog'
import CardreaderBlockDialog from './CardreaderBlock/Dialog'
import BuildingsNameSettings from './BuildingsNameSettings'
import BsidSettings from './BsidSettings'
import ContractFormContent from './ContractFormContent'

export default {
  components: {
    SchoolMeter,
    editClassroomNameCard,
    CardReaderRateDialog,
    CardreaderBlockDialog,
    BuildingsNameSettings,
    BsidSettings,
    ContractFormContent
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showContractOpacity: false,
      loadingPage: true,
      schoolData: null,
      dialog: false,
      valid: true,
      update: {
        loading: false,
        msg: ''
      },
      roofPvCapacity: ''
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      schoolType: 'config/schoolType',
      userRole: 'user/userRole',
      branchPlan: 'config/branchPlan'
    }),
    isSchoolAdmin() {
      return this.userRole.role === 'SCH_ADMIN'
    },
    editAuthority() {
      // return this.authorities.indexOf('UPDATE_SCHOOL') > -1
      return ['SCH_ADMIN'].includes(this.userRole.role)
    },
    allPvCapacity() {
      if (!this.schoolData || !this.schoolData.meterList) {
        return 0
      }
      return this.schoolData.meterList.reduce((accumulator, currentValue) => {
        if (currentValue.type !== 'SOLARMETER') {
          return Number(accumulator)
        }
        return Number(accumulator) + Number(currentValue.meterCapacity || 0)
      }, 0)
    },

    infoData() {
      if (!this.schoolData) return []
      else {
        return [
          {
            key: 'serialNumber',
            label: 'ID',
            type: 'text',
            value: this.schoolData.serialNumber,
            disabled: true
          },
          {
            key: 'name',
            label: '校名',
            type: 'text',
            value: this.schoolData.name,
            disabled: true,
            rules: [(v) => !!v || '必填']
          },
          {
            key: 'address',
            label: '地址',
            type: 'text',
            value: this.schoolData.address,
            disabled: true
          },
          {
            key: 'type', // todo
            label: '屬性',
            type: 'select',
            value: this.schoolData.type,
            disabled: true,
            attrs: {
              items: this.schoolType,
              itemText: 'name',
              itemValue: 'key'
            }
          },
          {
            key: 'manager',
            label: '學校負責人',
            type: 'text',
            value: this.schoolData.manager,
            disabled: this.isSchoolAdmin ? false : true,
            rules: [
              (v) => {
                if (!v) return true
                const pattern = /^[a-zA-Z.\s\u4e00-\u9fa5]+$/
                return pattern.test(v) || '格式不符'
              }
            ]
          },
          {
            key: 'tel',
            label: '學校聯絡電話',
            type: 'phone',
            value: this.schoolData.tel,
            disabled: this.isSchoolAdmin ? false : true,
            rules: [
              (v) => {
                if (!v) return true
                const pattern = /^[0-9()]+[0-9-#]+$/
                return pattern.test(v) || '格式不符'
              }
            ]
          },
          {
            key: 'managerEmail',
            label: '管理者E-mail',
            type: 'textarea',
            value: this.schoolData.managerEmail
              ? this.schoolData.managerEmail.join('\n')
              : null,
            disabled: true,
            attrs: {
              rows: 5
            }
          },
          {
            key: 'userEmail',
            label: '使用者E-mail',
            type: 'textarea',
            value: this.schoolData.userEmail
              ? this.schoolData.userEmail.join('\n')
              : null,
            disabled: true,
            attrs: {
              rows: 5
            }
          },
          {
            key: 'chargePlanCapacity',
            label: '全校太陽能裝置容量（kWp）',
            type: 'number',
            value: this.allPvCapacity,
            disabled: true
          },
          {
            key: 'roofPvCapacity',
            label: '屋頂光電裝置容量（kWp）',
            type: 'text',
            rules: [
              (v) => {
                if (!v) return true
                return Number(v).toString() === String(v) || '格式不符'
              }
            ],
            disabled: true
          },
          {
            key: 'fieldPvCapacity',
            label: '風雨球場裝置容量（kWp）',
            type: 'number',
            value: this.schoolData.fieldPvCapacity,
            disabled: this.isSchoolAdmin ? false : true,
            rules: [
              (v) => {
                let rule = new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/)
                if (!v) return true
                return rule.test(v) || '僅允許輸入最多小數點兩位的數字'
              }
            ]
          }
        ]
      }
    },

    saveData() {
      if (!this.schoolData) {
        return null
      }
      const infoData = this.infoData.reduce((map, d) => {
        map[d.key] = d.value
        return map
      }, {})
      infoData.meterList = this.schoolData.meterList
      infoData.roofPvCapacity = this.roofPvCapacity
      delete infoData.managerEmail
      delete infoData.userEmail
      delete infoData.chargePlanCapacity
      return infoData
    },

    fieldPvCapacityValid() {
      return this.schoolData.fieldPvCapacity <= this.allPvCapacity
    }
  },
  methods: {
    ...mapActions({
      getBranchPlan: 'config/getBranchPlans'
    }),
    async checkSubmit() {
      const validate = await this.$refs.form.validate()
      if (!validate) {
        const el = document.querySelector('.v-messages.error--text')
        if (el) this.$vuetify.goTo(el, { offset: 80 })
        return
      }
      this.update.loading = false
      this.update.msg = ''
      this.dialog = true
    },
    async submit() {
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.update.loading = true
      try {
        await BranchSchool.update(this.tagId, this.saveData)
        this.update.loading = false
        this.update.msg = '更新成功'
      } catch (error) {
        this.update.loading = false
        this.update.msg = '更新失敗'
        console.error(error)
      }
    },
    async getInfo() {
      if (!this.tagId) return
      try {
        const { data } = await BranchSchool.index(this.tagId)
        this.schoolData = data
        this.roofPvCapacity = this.schoolData.roofPvCapacity
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingPage = false
      }
    },
    updateRoofCapcity(fieldPvCapacity) {
      this.schoolData.fieldPvCapacity = fieldPvCapacity
      this.roofPvCapacity = this.allPvCapacity - this.schoolData.fieldPvCapacity
    },
    updateSolarMeterCapcity(meters) {
      this.schoolData = Object.assign(this.schoolData, {
        ...this.schoolData,
        meterList: meters
      })
      this.roofPvCapacity = this.allPvCapacity - this.schoolData.fieldPvCapacity
    }
  },
  async mounted() {
    this.getInfo()
    if (!this.branchPlan.length) {
      this.getBranchPlan()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field:not(.error--text) {
    &.v-input--is-disabled,
    &.v-input--is-readonly {
      .v-input__control > .v-input__slot {
        background-color: #d6e1f1;
        fieldset {
          border: none;
        }
        input,
        textarea,
        .v-select__selection {
          color: #889bb7;
        }
      }
    }
    .v-input__control > .v-input__slot {
      background-color: #ffffff;
      fieldset {
        border-color: #acc0dd;
      }
      input,
      textarea,
      .v-select__selection {
        color: var(--v-accent-base);
      }
    }
  }
}
</style>
