<template>
  <v-dialog v-model="showPopup" width="700" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="indigo" dark v-bind="attrs" v-on="on" class="my-3 mr-4">
        {{ title }}
      </v-btn>
    </template>

    <v-card class="pb-7" color="">
      <v-snackbar
        v-model="showUploadResult"
        absolute
        :color="uploadResult ? 'success' : 'error'"
        :multi-line="multiLine"
      >
        <span v-html="uploadResult ? '更新成功' : errorText"></span>
        <template v-slot:action="{ attrs }">
          <v-btn icon text v-bind="attrs" @click="showUploadResult = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-toolbar color="transparent" class="elevation-0 pa-0">
        <v-card-title class="px-0 accent--text font-weight-bold">{{
          title
        }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="showPopup = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-row class="ma-0 px-5">
        <v-col cols="6" class="px-5">
          <v-hover v-slot="{ hover }">
            <v-card
              height="300"
              :elevation="hover ? 10 : 3"
              class="pa-4"
              color="blue lighten-5"
              @click="download"
            >
              <v-card
                height="100%"
                flat
                color="blue darken-2"
                class="text-center"
              >
                <div class="card-avatar">
                  <v-avatar color="blue lighten-5" size="80">
                    <v-progress-circular
                      v-if="loadingDownload"
                      color="blue darken-2"
                      indeterminate
                    />
                    <v-icon v-else color="blue darken-2" large>
                      mdi-download
                    </v-icon>
                  </v-avatar>
                </div>
                <div class="mt-5 card-word">
                  <v-chip
                    color="blue lighten-5"
                    text-color="blue darken-2"
                    class="font-weight-bold"
                    >下載 BSID</v-chip
                  >
                </div>
              </v-card>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="6" class="px-5">
          <v-hover v-slot="{ hover }">
            <v-card
              height="300"
              :elevation="hover ? 10 : 3"
              class="pa-4"
              color="cyan lighten-5"
              @click="triggerSelectFile"
            >
              <v-card
                height="100%"
                flat
                color="cyan darken-3"
                class="text-center"
              >
                <div class="card-avatar">
                  <v-avatar color="cyan lighten-5" size="80">
                    <v-progress-circular
                      v-if="loadingUpload"
                      color="cyan darken-3"
                      indeterminate
                    />
                    <v-icon v-else color="cyan darken-3" large>
                      mdi-upload
                    </v-icon>
                  </v-avatar>
                </div>
                <div class="mt-5 card-word">
                  <v-chip
                    color="cyan lighten-5"
                    text-color="cyan darken-3"
                    class="font-weight-bold"
                    >更新 BSID</v-chip
                  >
                </div>
              </v-card>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-file-input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        v-model="files"
        ref="files"
        @change="upload"
        class="d-none"
      >
      </v-file-input>
    </v-card>
  </v-dialog>
</template>

<script>
import BranchSchool from '@/api/ems/BranchSchool'
import fileDownload from 'js-file-download'

export default {
  data() {
    return {
      showPopup: false,
      loadingDownload: false,
      loadingUpload: false,
      uploadResult: false,
      showUploadResult: false,
      title: '國教署 BSID 對照表',
      files: null,
      multiLine: false,
      errorText: '更新失敗'
    }
  },
  methods: {
    async download() {
      this.loadingDownload = true
      try {
        const response = await BranchSchool.downloadBsid()
        const fileName = decodeURI(
          response.headers['content-disposition']
            .split('attachment; filename="')[1]
            .slice(0, -1)
        )
        fileDownload(response.data, fileName)
      } catch (error) {
        console.error(error)
      }
      this.loadingDownload = false
    },
    triggerSelectFile() {
      this.files = null
      this.$nextTick(() => {
        this.$refs.files.$refs.input.click()
      })
    },
    async upload(File) {
      console.log(File)
      if (!File) {
        return
      }
      this.multiLine = false
      this.loadingUpload = true
      const formData = new FormData()
      formData.append('excel', this.files)
      try {
        await BranchSchool.uploadBsid(formData)
        this.uploadResult = true
      } catch (error) {
        this.uploadResult = false
        console.log(error.response)
        this.multiLine = true
        this.errorText = `更新失敗<br>${error.response.data.message}`
      }
      this.showUploadResult = true
      this.loadingUpload = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .card-avatar {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -65%);
  }

  .card-word {
    bottom: 16px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
</style>
