import settings from '@/settings'

/**
 * 讀卡機 黑名單
 */
class CardReaderBlack {
  /**
   * 取得列表
   */
  list(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/cardreader/blacklist`, {
      params: {
        schoolId: schoolId
      }
    })
  }

  /**
   * 新增卡片黑名單
   */
  create(schoolId, cardId) {
    return axios.post(
      settings.api.fullPath + `/ems/cardreader/blacklist`,
      {},
      {
        params: {
          schoolId: schoolId,
          card: cardId
        }
      }
    )
  }

  /**
   * 刪除卡片黑名單
   */
  delete(schoolId, cardId) {
    return axios.delete(settings.api.fullPath + `/ems/cardreader/blacklist`, {
      params: {
        schoolId: schoolId,
        card: cardId
      }
    })
  }
}

var cardReaderBlack = new CardReaderBlack()
export default cardReaderBlack
