var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6"},[_vm._l((_vm.meterGroup),function(group){return [(
        _vm.groupByTypeMeters[group.type] &&
        _vm.groupByTypeMeters[group.type].length > 0
      )?_c('v-card',{key:group.type,staticClass:"pa-3 ems-opacity-50-bg frosted-glass mb-4"},[_c('v-card-title',{staticClass:"accent--text font-weight-bold"},[_vm._v(_vm._s(group.name))]),_vm._l((_vm.groupByTypeMeters[group.type]),function(meter,index){return _c('v-card-text',{key:meter.id},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("電表名稱")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":!_vm.editAuthority,"disabled":!_vm.isSchoolAdmin,"rules":[
                function (v) { return !!v || '必填'; },
                function (v) {
                  var pattern = /^[_0-9a-zA-Z.,\s\u4e00-\u9fa5]+$/
                  return pattern.test(v) || '格式不符'
                }
              ]},model:{value:(meter.name),callback:function ($$v) {_vm.$set(meter, "name", $$v)},expression:"meter.name"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}}),(group.showAttrs.serialNumber)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("裝置編號")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":""},model:{value:(meter.serialNumber),callback:function ($$v) {_vm.$set(meter, "serialNumber", $$v)},expression:"meter.serialNumber"}})],1):_vm._e(),(group.showAttrs.chargePlanId)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("電費計價")]),_c('v-select',{attrs:{"readonly":!_vm.editAuthority,"outlined":"","dense":"","items":_vm.branchPlan,"item-text":"name","item-value":"id","disabled":!_vm.isSchoolAdmin},model:{value:(meter.chargePlanId),callback:function ($$v) {_vm.$set(meter, "chargePlanId", $$v)},expression:"meter.chargePlanId"}})],1):_vm._e(),(group.showAttrs.meterCapacity)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[(
                meter.type === 'SOLARMETER' || meter.type === 'VIRTUALPVMETER'
              )?_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v(" 裝置容量 kWp ")]):_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("契約容量 kW")]),(
                meter.type === 'SOLARMETER' || meter.type === 'VIRTUALPVMETER'
              )?_c('v-text-field',{attrs:{"value":meter.meterCapacity,"outlined":"","dense":"","readonly":!_vm.editAuthority,"rules":[function (v) { return v === 0 || !!v || '必填'; }, _vm.limit2DecimalPlaces],"type":"number","disabled":!_vm.isSchoolAdmin},on:{"input":function($event){return _vm.updateSolarMeterCapcity($event, meter)}}}):_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":!_vm.editAuthority,"rules":[function (v) { return v === 0 || !!v || '必填'; }, _vm.limit2DecimalPlaces],"type":"number","disabled":!_vm.isSchoolAdmin},model:{value:(meter.meterCapacity),callback:function ($$v) {_vm.$set(meter, "meterCapacity", $$v)},expression:"meter.meterCapacity"}})],1):_vm._e()],1)],1)})],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }