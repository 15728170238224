<template>
  <v-card class="SchoolCardReaderRateCard pa-3 mb-4">
    <v-toolbar color="transparent" class="elevation-0 pa-0">
      <v-card-title class="px-0 accent--text font-weight-bold">
        卡機費率設定
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn @click="close()" plain>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="cardreaders"
          :items-per-page="cardreaders.length"
          hide-default-footer
          disable-sort
          show-select
          item-key="emsclasscardreaderid"
        >
          <template v-slot:top>
            <v-sheet color="white" elevation="1" class="pa-4" width="300">
              <div class="subtitle-1 accent--text font-weight-bold">
                依勾選項目設定費率
              </div>
              <v-row align="center" class="mt-0">
                <v-col>
                  <v-text-field
                    v-permission-without-city="['keelung', 'changhua']"
                    v-model="rate"
                    filled
                    solo
                    placeholder="請輸入費率"
                    width="300"
                    :rules="[
                      (v) => !!v || '必填',
                      (v) => {
                        return Number(v).toString() === String(v) || '格式不符'
                      },
                      (v) => {
                        return (
                          (Number(v) >= 0.01 && Number(v) <= 99.99) ||
                          '需介於 0.01 ~ 99.99'
                        )
                      },
                      (v) => {
                        return (
                          numeral(v).format('0.[00]') === String(v) ||
                          `小數點最多2位數`
                        )
                      }
                    ]"
                  ></v-text-field>
                  <v-text-field
                    v-permission-with-city="['keelung', 'changhua']"
                    v-model="rate"
                    filled
                    solo
                    placeholder="請輸入費率"
                    width="300"
                    :rules="[
                      (v) => !!v || '必填',
                      (v) => {
                        return Number(v).toString() === String(v) || '格式不符'
                      },
                      (v) => {
                        return (
                          (Number(v) >= 0.1 && Number(v) <= 63.9) ||
                          '需介於 0.1 ~ 63.9'
                        )
                      },
                      (v) => {
                        return (
                          numeral(v).format('0.[0]') === String(v) ||
                          `小數點最多1位數`
                        )
                      }
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    @click="save()"
                    :disabled="
                      !valid || !rate || !selected || selected.length === 0
                    "
                    color="primary"
                  >
                    批次修改
                  </v-btn>
                  <div
                    v-if="!selected || selected.length === 0"
                    class="error--text caption"
                  >
                    請選擇卡機
                  </div>
                  <div v-else class="mb-5"></div>
                </v-col>
              </v-row>
            </v-sheet>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardReader from '@/api/ems/CardReader'

export default {
  name: 'SchoolCardReaderRateCard',
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      valid: true,
      rate: null,
      cardreaders: [],
      selected: [],
      headers: [
        {
          text: '卡機名稱',
          value: 'deviceName'
        },
        {
          text: '目前費率',
          value: 'emsairconditioningchargerate'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      navs: 'page/navs'
    }),
    selectedIds() {
      return this.selected.map((item) => item.emsclasscardreaderid)
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getSchoolCardreader()

    // 移除使用 v-permission-without-city  v-permission-without-city 移除元件的監測
    this.$refs.form.inputs.forEach((input) => {
      if (this.$refs.form.$el[0].id !== input.$refs.input.id) {
        this.$refs.form.unregister(input)
      }
    })
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getTags: 'page/getTags',
      showMsg: 'snackbar/show',
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    close() {
      this.$emit('close', true)
    },
    getSchoolCardreader() {
      CardReader.getSchoolCardreader(this.schoolId)
        .then((resp) => {
          this.cardreaders = resp.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    save() {
      this.setOverlayLoading(true)
      CardReader.setSchoolCardreaderRate(
        this.schoolId,
        this.selectedIds,
        this.rate
      )
        .then((resp) => {
          this.getTags()
          this.showMsg({
            message: '更新成功',
            closable: true,
            time: 3000
          })
          this.getSchoolCardreader()
          this.close()
        })
        .catch(() => {
          this.showMsg({
            message: '更新失敗',
            color: 'error',
            closable: true,
            time: 3000
          })
        })
        .finally(() => {
          this.setOverlayLoading(false)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/schoolInfoDialog.scss';
</style>
